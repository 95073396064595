import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  ConceptMedia,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                ACCESSORIES <br className="u_sp" />
                BOUTIQUE
              </>
            ),
            sub: 'アクセサリーブティック',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/shop/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/shop/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: 'ショップ',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <h2 className="c_headingLv2 u_colorGold">
            2F アクセサリーブティック
            <br className="u_sp" />
            「アビステ」
          </h2>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '営業時間',
                text: <>10:00～19:00</>,
              },
              {
                title: 'お問合せ',
                text: (
                  <>
                    <a href="tel:0456620223">TEL 045-662-0223</a>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
